<template>
  <!-- 交通信息 -->
  <div class="tabDetailWrap traffic en">
    <div>
      <div class="tabDetail">
        <img class="tabLogo" :src="require('../../assets/image/traffic_en/title.png')" alt />
        <!-- 路线详情 -->
        <div class="mapWrap">
          <img class="map" :src="require('../../assets/image/traffic_en/tranffic_bg.png')" alt />
        </div>
        <!-- 自驾路线 -->
        <div class="titleWrap group ziJia">
          <img class="icon" :src="require('../../assets/image/traffic/route_icon.png')" alt />
          <span class="title">Route by car</span>
        </div>
        <div class="routeDetail">
          <img class="bg" :src="require('../../assets/image/traffic/route_bg.png')" alt />
          <div class="groupWrap" v-html="dataInfo.self_driving"></div>
        </div>
        <!-- 公交路线 -->
        <div class="titleWrap group bus">
          <img class="icon" :src="require('../../assets/image/traffic/bus_icon.png')" alt />
          <span class="title">Bus routes</span>
        </div>
        <div class="routeDetail bus">
          <img class="bg" :src="require('../../assets/image/traffic/bus_bg.png')" alt />
          <div class="groupWrap" v-html="dataInfo.bus"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import axios from "axios";
import config from "@/utils/config";
export default {
  data() {
    return {
      dataInfo: {}
    };
  },
  mounted() {
    this.getData(config.serviceTraffic_en);
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  methods: {
    async getData(url) {
      try {
        this.id = this.$route.query.id;
        let res = await axios.get(url, {
          params: {
            id: this.id
          }
        });
        if (res.data.code == "0" && res.data.data instanceof Array) {
          if (res.data.data.length > 0) {
            let tempData = JSON.parse(JSON.stringify(res.data.data));
            if (tempData.length > 0) {
              this.dataInfo = tempData[0];
            }

            // console.log("res.data.data:", this.dataInfo);
          }
        }
      } catch (e) {
        console.log("res err:", e);
      }
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.tabDetailWrap {
  min-height: 500px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 50px;
  .tabDetail {
    // padding-left: 40px;
    // padding-right: 40px;
    // padding-top: 50px;
    > .tabLogo {
      vertical-align: super;
      margin-right: 47px;
      // width: 117px;
      // height: 88px;
    }
    .logoRight {
      display: inline-block;
      > .title {
        font-size: 45px;
        font-weight: bold;
        color: $main-orange;
        margin-bottom: 20px;
      }
      > .subtitle {
        margin-top: 20px;
      }
    }

    > .mapWrap {
      height: 524px;
      margin-top: 47px;
      overflow: hidden;
    }
    > .titleWrap.group {
      position: relative;
      width: 817px;
      height: 69px;
      line-height: 69px;
      margin-top: 57px;
      margin-left: 20px;
      background: $bg-orange2;
      border-radius: 30px;
      box-sizing: border-box;
      padding-left: 79px;
      .icon {
        position: absolute;
        left: -20px;
        top: -4px;
      }
      > .title {
        font-size: 36px;
        color: $main-white;
        font-weight: bold;
        vertical-align: super;
        margin-right: 34px;
      }
    }

    .routeDetail {
      position: relative;
      width: 1000px;
      height: 480px;
      margin-top: 28px;
      .groupWrap {
        position: absolute;
        width: 954px;
        height: 424px;
        overflow: hidden;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        .infoGroup {
          margin-top: 25px;
          > .title,
          > .detail {
            display: block;
          }
          > .title {
            font-size: 21px;
            font-weight: bold;
            color: $bg-orange2;
          }
          > .detail {
            margin-top: 9px;
            font-size: 15px;
            color: $main-black;
            line-height: 1.5;
          }
        }
        .infoGroup:nth-of-type(1) {
          margin-top: 0;
        }
      }
    }

    .routeDetail.bus {
      .groupWrap {
        .infoGroup {
          > .title {
            color: $main-blue;
          }
        }
      }
    }

    .tabDetailWrap .tabDetail .routeDetail[data-v-65643cd0] {
      position: relative;
      width: 1000px;
      height: 460px;
      margin-top: 28px;
    }

    .titleWrap.group.bus {
      background: $main-blue;
      padding-left: 60px;
      .title {
        vertical-align: baseline;
      }
    }
    .routeDetail.bus {
      height: auto;
      margin-bottom: 30px;
      padding-top: 26px;
      padding-bottom: 26px;
      .bg {
        width: 1000px;
        height: 301px;
      }
      > .groupWrap {
        height: 301px;
        padding-top: 26px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
